import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/pro-regular-svg-icons";
import * as React from "react";
import {useState} from "react";

const TicketAdder = (props) => {
	const [numTickets, setNumTickets] = useState(props.initial || 0);
	const min = props.min || 0;

	const canFewer = () => {
		return numTickets > min;
	}

	const canMore = () => {
		return numTickets < props.max;
	}

	const fewer = () => {
		if (canFewer()) {
			const newValue = parseInt(numTickets) - 1;
			props.onChange(newValue);
			setNumTickets(newValue);
		}
	}

	const more = () => {
		if (canMore()) {
			const newValue = parseInt(numTickets) + 1;
			props.onChange(newValue);
			setNumTickets(newValue);
		}
	}

	return (
		<div className="ticket-adder">
			<div className={classNames({'ticket-adder__fewer': true, 'ticket-adder__fewer--disabled': !canFewer()})}>
				<button onClick={fewer}>
					<FontAwesomeIcon icon={faMinus} />
				</button>
			</div>
			<div className="ticket-adder__count">{numTickets}</div>
			<div className={classNames({'ticket-adder__more': true, 'ticket-adder__more--disabled': !canMore()})}>
				<button onClick={more}>
					<FontAwesomeIcon icon={faPlus} />
				</button>
			</div>
		</div>
	)
}

export default TicketAdder