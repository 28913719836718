import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinusCircle} from "@fortawesome/pro-regular-svg-icons";
import * as React from "react";
import Link from "gatsby-link";
import EventType from "./eventType";
import NumberFormat from "react-number-format";
import {useContext} from "react";
import BasketContext from "../contexts/basket";
import TicketAdder from "./ticketAdder";
import moment from 'moment'

const Ticket = (props) => {
    const {removeItem, updateItem} = useContext(BasketContext);

    const changeQuantity = (newNum) => {
        updateItem(props.data, {
            quantity: newNum
        });
    }

    const remove = () => {
        if (window.confirm('Are you sure you want to remove these tickets from your basket?')) {
            removeItem(props.data);
        }
    }

	return (
        <article className="ticket">
            <header className="ticket__header">
                <div className="ticket__header__main">
                    <div className="event-name">
                    <div className="ticket__header__main__type">
                        <EventType data={props.data.item}/>
                    </div>
                    <h3>
                        <Link to={'/events/' + props.data.item.slug}>{props.data.item.artist?.name }</Link>
                    </h3>
                    </div>
                    <div className="event-details">
                    <span>{props.data.item.name} - {props.data.item.venue?.name}</span>
                    <span>{moment(props.data.item.starts).format('MMMM D, Y [at] HH:mm')}</span>
                    </div>
                </div>
                <div className="ticket__header__remove">
                    <button onClick={remove}>
                        <FontAwesomeIcon icon={faMinusCircle}/>
                    </button>
                </div>
            </header>

            <main className="ticket__main">
                <div className="ticket__main--left">
                    <dl className="ticket__main__def">
                        <dt className="ticket__main__def--face-value">Ticket face value</dt>
                        <dd className="ticket__main__def--face-value ticket__main__def--face-value--strikethrough">
                            <NumberFormat value={props.data.item.ticket_face_value} displayType={"text"}
                                          fixedDecimalScale={true} decimalScale={2} thousandSeparator={true}
                                          prefix={'£'}/>
                        </dd>

                        <dt className="single-ticket-deposit">Single ticket fee
                            <small>If your ballot is unsuccessful, no money is automatically taken. However,
								there will be an option to donate your fee to Concerts for Carers.</small>
                        </dt>

                        <dd className="single-ticket-price">
                            <NumberFormat value={props.data.subtotal_pre_discount / 100} displayType={"text"} fixedDecimalScale={true}
                                          decimalScale={2} thousandSeparator={true} prefix={'£'}/>
							<br/><small>min. <NumberFormat value={(props.data.subtotal_pre_discount * 2) / 100} displayType={"text"} fixedDecimalScale={true}
														   decimalScale={2} thousandSeparator={true} prefix={'£'}/></small>
                        </dd>
                    </dl>

                        <dl className="ticket-controls">

                            <dt>Number of tickets</dt>
                            <dd>
                                <TicketAdder initial={props.data.quantity} min={1}
                                             max={props.data.item.max_tickets_per_transaction}
                                             onChange={changeQuantity}/>
                            </dd>
                        </dl>

                </div>

                <div className="ticket__main--right">
                    <dl className="ticket__main__def">
                        <dt className="ticket__main__def--face-value">Total face value</dt>
                        <dd className="ticket__main__def--face-value ticket__main__def--face-value--strikethrough">
                            <NumberFormat value={props.data.total_face_value / 100} displayType={"text"}
                                          fixedDecimalScale={true} decimalScale={2} thousandSeparator={true}
                                          prefix={'£'}/>
                        </dd>

                        <dt className="deposit-total">Ballot fee total</dt>
                        <dd className="single-ticket-price">
                            <NumberFormat value={props.data.total / 100} displayType={"text"} fixedDecimalScale={true}
                                          decimalScale={2} thousandSeparator={true} prefix={'£'}/>
                        </dd>
                    </dl>
                </div>
            </main>
        </article>
    )
}

export default Ticket