
// Libraries
import * as React from 'react'
import { navigate } from 'gatsby'

// Services
import UserService from '../services/user'
import AuthService from '../services/auth'

// Context
import NotificationContext from '../contexts/notification'

class CheckoutMiddleware extends React.Component {
	static contextType = NotificationContext

	render() {
		return (
			this.props.children
		)
	}

	async componentDidMount() {
		const userService = new UserService(),
		canCheckout = await userService.canCheckout(),
		authService = new AuthService(),
		authorised = await authService.isLoggedIn()

		const {addNotification} = this.context

		if(!canCheckout) {
			addNotification('Your account has been flagged because you have previously won tickets to an event that you did not attend. If you would like to appeal this, please contact office@concertsforcarers.org.uk', 'error')
			navigate('/')
		}

		if(!authorised) {
			navigate('/login')
		}

		await authService.isLoggedIn()
	}
}

export default CheckoutMiddleware
