// Libraries
import * as React from 'react'

// Components
import Layout from '../../components/layout'
import Ticket from '../../components/ticket'
import FloatingCheckout from '../../components/floatingCheckout'
import Button from '../../components/button'
import Seo from '../../components/seo'

// Contexts
import BasketContext from '../../contexts/basket'

// Middleware
import CheckoutMiddleware from '../../middleware/checkoutMiddleware'

class Basket extends React.Component {
	render() {
		return (
			<CheckoutMiddleware>
				<Layout className="">
					<Seo title="Basket" />
					<section className="generic-template basket-template">
						<div className="container generic-template--parent">
							<div className="container generic-template--container">
								<h1 className="generic-template__title outline-title basket-title">My Basket</h1>
								<p>Below are the shows that you have added to your basket.</p>
								<p>No payment is required up front for ballot entries. If you are successful in winning
									tickets, the card details you enter during checkout will be used to collect
									payment. We will not take any money if you do not win the ballot.</p>

								<BasketContext.Consumer>
									{value => (
										<>
											{value.itemCount > 0
												? <>
													<FloatingCheckout basket={value}/>
													<ul className="ticket-list">
														{
															value.items.map((item) => {
																return (
																	<li className="ticket-list__ticket">
																		<Ticket data={item}/>
																	</li>
																)
															})
														}
													</ul>
												</>
												: <>
													<p>Your basket is currently empty</p>
													<Button to="/events" hollow xsBlock>Explore Events</Button>
												</>
											}
										</>
									)}
								</BasketContext.Consumer>
							</div>
						</div>
					</section>
				</Layout>
			</CheckoutMiddleware>
		)
	}
}

export default Basket
