import * as React from 'react';

const EventType = (props) => {
	return (
		<>
			{props.data.type.category?.name ? props.data.type.category.name + ' - ' : ''}
			{props.data.type.name}
		</>
	)
}

export default EventType